import '@babel/polyfill'

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
      }
  };
}

function router(classList){
  let rout = 'base.js'

  if(classList.contains('entry-home')){
    rout = 'home.js'
  }

  if(classList.contains('entry-find')){
    rout = 'find.js'
  }

  if(classList.contains('entry-branntipseksempler')){
    rout = 'examples.js'
  }

  import('./routes/' + rout).then(module => {
    const newPage = new module.default()
    newPage.init()
  })
}

router(document.querySelector('body').classList)